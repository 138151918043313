import React, { useEffect } from 'react';
import { ChildSegments } from './child-segments/child-segments';
import { HeroSearch } from './hero-search/hero-search';
import { useGtm } from '../../custom-hooks/gtm-hook';
import { GtmEvents } from '../../helpers/web-analytics.helpers';
import Container from '../layout/container';

const MainLanding: React.FC = () => {
    useGtm();

    useEffect(() => {
        GtmEvents.pageEvent('/');
        localStorage.removeItem('chat');
        sessionStorage.removeItem('isReturnBack');
    }, []);

    return (
        <Container>
            <HeroSearch title="для частных клиентов" />
            <ChildSegments />
        </Container>
    );
};

export default MainLanding;
